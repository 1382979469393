import GUI from 'lil-gui'
import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { gsap } from 'gsap'
import firefliesVertexShader from "./shaders/fireflies/vertex.glsl"
import firefliesFragmentShader from "./shaders/fireflies/fragment.glsl"
import portalVertexShader from "./shaders/portal/vertex.glsl"
import portalFragmentShader from "./shaders/portal/fragment.glsl"
import lumiVertexShader from "./shaders/lumi/vertex.glsl"
import lumiFragmentShader from "./shaders/lumi/fragment.glsl"
import overlayVertexShader from "./shaders/overlay/vertex.glsl"
import overlayFragmentShader from "./shaders/overlay/fragment.glsl"


/**
 * Base
 */
// Debug
const debugObject = {}
const gui = new GUI({
    width: 400,
})
gui.hide()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Overlay
 */
const overlayGeomeetria = new THREE.PlaneGeometry(2, 2, 1, 1) // viimased 2 on subdivision
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    depthWrite: false,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: overlayVertexShader,
    fragmentShader: overlayFragmentShader
})
const overlay = new THREE.Mesh(overlayGeomeetria, overlayMaterial)
overlay.renderOrder = 2
scene.add(overlay)

/**
 * Loaders
 */
// niimoodi saab kätte DOM elemendi (meie puhul html-css) ja anda sellele javascriptis väärtus
const loadingBarElement = document.querySelector(".loading-bar") 


const loadingManager = new THREE.LoadingManager(
    // Laetud
    () =>
    {   
        window.setTimeout( () =>
        {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration:3, value: 0}) // https://gsap.com/docs/v3/GSAP/gsap.to()
            //console.log("laetud")
            loadingBarElement.classList.add("ended") // kasutab css-i klassi ended, kus triip liigub ära
            loadingBarElement.style.transform = "" // tühi, kuna peame css-i loading-bar scaleX-i saama uuesti nulli, kuna all progressis panime selle 1-peale.
        }, 500) // millisekundid, kui palju ootab enne kui eest ära hakkab liikuma

    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {

        //console.log(itemUrl, itemsLoaded, itemsTotal)
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})` // transform ja scale samad, mis css-is
    }
   
)

// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager)

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager)




/**
 * Tekstuurid
 */
const bakedTexture = textureLoader.load("/texture/baked_koik02_4k.jpg") // kui ftp-s, siis võib kasutada ka /baked.jpg
bakedTexture.flipY = false // flipime tekstuuri, sest muidu näitab valesti
bakedTexture.colorSpace = THREE.SRGBColorSpace

const tekstiTekstuur = textureLoader.load("/texture/tekst_baked2.jpg")
tekstiTekstuur.flipY = false
tekstiTekstuur.colorSpace = THREE.SRGBColorSpace

/**
 * Materjalid
 */

// Baked materjal
const bakedMaterjal = new THREE.MeshBasicMaterial({ map: bakedTexture})
const tekstiBakedMaterjal = new THREE.MeshBasicMaterial({ map: tekstiTekstuur})

// Teised materjalid
const heleValgus = new THREE.MeshBasicMaterial({ color: 0xfff2ab })
const laternaMaterjal = new THREE.MeshBasicMaterial({ color: 0xedd863 })
const mixdLogoValgus = new THREE.MeshBasicMaterial({ color: 0xFDA4D5 })

/**
 * Video
 */
const video = document.getElementById("video")
const videoTex = new THREE.VideoTexture(video)
videoTex.minFilter = THREE.LinearFilter
videoTex.magFilter = THREE.LinearFilter
videoTex.colorSpace = THREE.SRGBColorSpace

const videoMaterjal = new THREE.MeshBasicMaterial({
    map: videoTex,
})

videoMaterjal.map.flipY = false
video.play()

// Portrali materjal
debugObject.portalColorStart = "#fff7c2"
debugObject.portalColorEnd = "#f4a9b1"

// gui
//     .addColor(debugObject,"portalColorStart" )
//     .onChange(() =>
//     {
//         portalLightMaterial.uniforms.uColorStart.value.set(debugObject.portalColorStart)
//     })

// gui
//     .addColor(debugObject,"portalColorEnd" )
//     .onChange(() =>
//     {
//         portalLightMaterial.uniforms.uColorEnd.value.set(debugObject.portalColorEnd)
//     })

const portalLightMaterial = new THREE.ShaderMaterial({
    uniforms:
    {
        uTime: { value: 0 },
        uColorStart: { value: new THREE.Color(debugObject.portalColorStart)}, // portaali keskmine värv
        uColorEnd: { value: new THREE.Color(debugObject.portalColorEnd)} // portaali äärte värv
    },
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader
})



/**
 * Model
 */

gltfLoader.load(
    'tekstid.glb',
    (gltf) =>
    {
        gltf.scene.scale.set(0.22, 0.22, 0.22)
        gltf.scene.rotation.y =  - Math.PI * 0.5
        scene.add(gltf.scene)
        

        const Tekst = gltf.scene.children.find(child => child.name === "joulutekst")
        

        Tekst.material = tekstiBakedMaterjal
    }
)  



let mixer = null // et tuua "mixer" väljapoole funktsiooni, saaks kasutada all tick-is

gltfLoader.load(
    "joulumaa_anim2.glb",
    (gltf) =>
    {
         // Kui 3D mudel koosneb animation-clipsidest:
         mixer = new THREE.AnimationMixer(gltf.scene) // nagu playlist, kus on kõik muusikapalad sees
         const action = mixer.clipAction(gltf.animations[0]) // võtame playlistist ühe loo (animatsiooni)
 
         action.play() // tick funktsioonil peab mixerit igal framel update-ma

        gltf.scene.traverse((child) => // traverse käib läbi kõik child-id
        {
            //console.log(child)
            child.material = bakedMaterjal
        })

        // kasutame js-i findi, mis otsib child hulgast vajaliku üles
        //const bakedMesh = gltf.scene.children.find(child => child.name === "baked")
        const Kuu = gltf.scene.children.find(child => child.name === "Kuu")
        const akenUleval = gltf.scene.children.find(child => child.name === "akenUleval") 
        const aukHelendab = gltf.scene.children.find(child => child.name === "aukHelendab")
        const laternTuliParemal = gltf.scene.children.find(child => child.name === "laternTuliParemal") 
        const laternTuliVasakul = gltf.scene.children.find(child => child.name === "laternTuliVasakul")
        const klaas1helendab = gltf.scene.children.find(child => child.name === "klaas1helendab") 
        const klaas2helendab = gltf.scene.children.find(child => child.name === "klaas2helendab")
        const klaasHelendab = gltf.scene.children.find(child => child.name === "klaasHelendab") 
        const mixdLogoHelendab = gltf.scene.children.find(child => child.name === "mixdLogoHelendab") 
        const nooleTulukesed = gltf.scene.children.find(child => child.name === "nooleTulukesed") 
        const ekraan = gltf.scene.children.find(child => child.name === "ekraan") 



        //bakedMesh.material = bakedMaterjal
        laternTuliParemal.material = laternaMaterjal
        laternTuliVasakul.material = laternaMaterjal
        Kuu.material = heleValgus
        akenUleval.material = heleValgus
        aukHelendab.material = portalLightMaterial
        klaas1helendab.material = heleValgus
        klaas2helendab.material = heleValgus
        klaasHelendab.material = heleValgus
        mixdLogoHelendab.material = mixdLogoValgus
        nooleTulukesed.material = heleValgus
        ekraan.material = videoMaterjal
        
        scene.add(gltf.scene)
    }
)

 
        



/**
 * Fireflies tunneli sees
 */

// Geomeetria
const firefliesGeometry = new THREE.BufferGeometry()
const firefliesArv = 30
const positionArray = new Float32Array(firefliesArv * 3) // 3, sest peame andma väärtuse x, y, z-le
const scaleArrayRandom = new Float32Array(firefliesArv) // igale 1 random scale nr

//nüüd anname neile x, y, z-le väärtused
for( let i = 0; i < firefliesArv; i++)
{
    positionArray[i * 3 + 0] = (Math.random() - 0.3) * 0.6 //X. Annab koordiaandid array kohtadele 0, 3, 6 jne. * 0.6 annab mõõtmed
    positionArray[i * 3 + 1] = (Math.random() + 1.3) * 0.2 //Y, kõrgus, paksus. Annab koordiaandid array kohtadele 1, 4, 7 jne
    positionArray[i * 3 + 2] = (Math.random() - 1.2) * 0.3 //Z. Annab koordiaandid array kohtadele 2, 5, 8 jne

    scaleArrayRandom[i] = Math.random() // random arv 0..1
}

firefliesGeometry.setAttribute("position", new THREE.BufferAttribute(positionArray, 3))// 3, sest x, y, z
firefliesGeometry.setAttribute("randomSuurus", new THREE.BufferAttribute(scaleArrayRandom, 1))

// Fireflies MATERJAL
const firefliesMaterial = new THREE.ShaderMaterial({
    uniforms: // et saada ruudukesed samaks kõigil ekraanidel
    {
        uTime: { value: 0 },
        uPixelRatio: {value: Math.min(window.devicePixelRatio, 2)}, 
        uSize: { value: 100 } // vertex shaderis
    },
    vertexShader: firefliesVertexShader,
    fragmentShader: firefliesFragmentShader,
    transparent: true,
    blending: THREE.AdditiveBlending, // halb performance-le, ei pea panema
    depthWrite: false // võtab ära selle, et particle varjab ära taga oleva particlese

 })

 gui.add(firefliesMaterial.uniforms.uSize, "value").min(0).max(100).step(1).name("firefliesSize")


//Points
const fireflies = new THREE.Points(firefliesGeometry, firefliesMaterial)
scene.add(fireflies)

/**
 * LUMI
*/
// Geomeetria
const lumeGeomeetria = new THREE.BufferGeometry()
const lumeArv = 7300
const lumePosArray = new Float32Array(lumeArv * 3)
const lumeSuurusArrayRandom = new Float32Array(lumeArv)
const progressArray = new Float32Array(lumeArv)

//nüüd anname neile x, y, z-le väärtused
for( let i = 0; i < lumeArv; i++)
{
    lumePosArray[i * 3 + 0] = (Math.random() - 0.5) * 4
    lumePosArray[i * 3 + 1] = (Math.random()+ 0.3) * 2.4
    lumePosArray[i * 3 + 2] = (Math.random() - 0.5) * 4

    lumeSuurusArrayRandom[i] = Math.random()
    progressArray[i] = Math.random()

}

lumeGeomeetria.setAttribute("position", new THREE.BufferAttribute(lumePosArray, 3))
lumeGeomeetria.setAttribute("LumeRandomSuurus", new THREE.BufferAttribute(lumeSuurusArrayRandom, 1))
lumeGeomeetria.setAttribute("aProgress", new THREE.BufferAttribute(progressArray, 1))



// Lume materjal
const lumeMaterjal = new THREE.ShaderMaterial({
    uniforms: // et saada ruudukesed samaks kõigil ekraanidel
    {
        uTime: { value: 0 },
        uPixelRatio: {value: Math.min(window.devicePixelRatio, 2)}, 
        uSize: { value: 41 } // vertex shaderis
    },
    vertexShader: lumiVertexShader,
    fragmentShader: lumiFragmentShader,
    transparent: true,
   //blending: THREE.AdditiveBlending, // halb performance-le, ei pea panema
    depthWrite: false // võtab ära selle, et particle varjab ära taga oleva particlese

 })

 gui.add(lumeMaterjal.uniforms.uSize, "value").min(0).max(100).step(1).name("lume suurus")


// Points
const lumi = new THREE.Points(lumeGeomeetria, lumeMaterjal)
lumi.position.y = 1.5
scene.add(lumi)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update fireflies suurused erinevatel ekraanidel
    firefliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)

})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(65, sizes.width / sizes.height, 0.1, 100)
// camera.position.x = 3.2
// camera.position.y = 0.7
// camera.position.z = 0.8
camera.position.x = 5.4
camera.position.y = 1.1
camera.position.z = 3

scene.add(camera)

gui
    .add(camera.position, "x")
    .min(0)
    .max(6)
    .step(0.1)
    .name("kaamera X")

gui
    .add(camera.position, "y")
    .min(-3)
    .max(3)
    .step(0.1)
    .name("kaamera Y")

gui
    .add(camera.position, "z")
    .min(-3)
    .max(3)
    .step(0.1)
    .name("kaamera Z")

console.log(camera.position.y)


// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.x = 0.55
controls.target.y = 0.7
controls.enableDamping = true
controls.maxDistance = 6
controls.minDistance = 0.3
controls.maxAzimuthAngle = Math.PI // piirang kaamera liikumisel üles
controls.minAzimuthAngle =  - Math.PI * 0.01 // piirang kaamera liikumisel paremale ja vasakule
controls.maxPolarAngle = 0.54 * Math.PI // piirang kaamera pööramisel alla
controls.screenSpacePanning = false // see ei luba Shiftiga alla pannida

gui
    .add(controls.target, "x")
    .min(-2)
    .max(2)
    .step(0.1)
    .name("Target X")

gui
    .add(controls.target, "y")
    .min(-2)
    .max(2)
    .step(0.1)
    .name("Target Y")

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

debugObject.taustaVärv = "#101b23"
renderer.setClearColor(debugObject.taustaVärv)
gui
    .addColor(debugObject, "taustaVärv")
    .onChange( ()=>
    {
        renderer.setClearColor(debugObject.taustaVärv)
    })


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update materials
    firefliesMaterial.uniforms.uTime.value = elapsedTime //uTime saab elapsedTime väärtuse
    portalLightMaterial.uniforms.uTime.value = elapsedTime //uTime saab elapsedTime väärtuse


    // Update Mixer, mixer on praegu let-iga määratud algusesl null, seega
    // et ei tuleks errorit, on vaja öelda, "et kui mixer ei oleks null"
    if( mixer !== null) // 
    {
        mixer.update(deltaTime)
    }
   
    // Update Lumi
    lumeMaterjal.uniforms.uTime.value = elapsedTime


    // Update controls
    controls.update()
    console.log(controls.position0)

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()