uniform float uTime;
uniform float uPixelRatio;
uniform float uSize;

attribute float randomSuurus;

void main()
{
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);

    // kasutame 1 particle x-koordinaati
    // korda randomSuurus -> väiksed liiguvad vähem, suuremad rohkem
    modelPosition.y += sin(uTime + modelPosition.x * 100.0) * randomSuurus * 0.2; // kui uTime * 10, siis kiirem liikumine

    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;

    gl_Position = projectionPosition;

    gl_PointSize = uSize * randomSuurus * uPixelRatio;
    gl_PointSize *= (1.0 / - viewPosition.z); //sama mis sizeAttenuation, ehk kaamera sisseliikudes sama suur
}